.footer {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 10px 20px;
}

/* Dark mode styling */
.dark-mode .footer {
  background-color: #000000;
  color: white;
}

/* Light mode styling */
.light-mode .footer {
  background-color: #ffffff;
  color: black;
}


 
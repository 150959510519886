.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-image: url("ballbg.jpg");
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  background-size: cover; /* Stretch the image to cover the entire header area */
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-container img {
  height: 70px;
  margin-right: 10px;
}

.logo-container .brand-name {
  font-weight: bold;
}

nav {
  margin-left: auto; /* Push the navigation to the right side */
}

nav ul {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
  background-color:black;
}

nav ul li {
  margin: 0;
}

nav ul li a {
  text-decoration: none;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}

.toggle-switch {
  display: flex;
  align-items: center;
  font-size:10pt;
  margin-left: 10px; /* Add some space between the nav and toggle switch */
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: black;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Dark mode styling */
.dark-mode {
  background-color: #000000;
  color: white;
}

.dark-mode .logo-container .brand-name,
.dark-mode nav ul li a {
  color: white;
}

/* Light mode styling */
.light-mode {
  background-color: #ffffff;
  color: black;
}

.light-mode .logo-container .brand-name,
.light-mode nav ul li a {
  color: white;
}

html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dark-mode {
  background-color: #000000;
  color: white;
}

.light-mode {
  background-color: #ffffff;
  color: black; /* Updated color property */
}

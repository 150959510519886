body {
  margin: 0;
  font-family: verdana, sand-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.dark-mode {
  --bg-color: #000000;
  --text-color: white;
}

.light-mode {
  --bg-color: #ffffff;
  --text-color: black;
}
